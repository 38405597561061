/**
 * Generated by `createschema news.NewsItemRecipientsExportTask 'user:Relationship:User:CASCADE; status:Select:processing,completed,error; file?:File'`
 */

import {
    NewsItemRecipientsExportTask,
    NewsItemRecipientsExportTaskCreateInput,
    NewsItemRecipientsExportTaskUpdateInput,
    QueryAllNewsItemRecipientsExportTasksArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { NewsItemRecipientsExportTask as NewsItemRecipientsExportTaskGQL } from '@condo/domains/news/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<NewsItemRecipientsExportTask, NewsItemRecipientsExportTaskCreateInput, NewsItemRecipientsExportTaskUpdateInput, QueryAllNewsItemRecipientsExportTasksArgs>(NewsItemRecipientsExportTaskGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
