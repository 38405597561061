/**
 * Generated by `createschema banking.BankIntegrationContext 'integration:Relationship:BankIntegration:CASCADE; organization:Relationship:Organization:CASCADE; enabled:Checkbox'`
 */

import {
    BankIntegrationAccountContext,
    BankIntegrationAccountContextCreateInput,
    BankIntegrationAccountContextUpdateInput,
    QueryAllBankIntegrationAccountContextsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { BankIntegrationAccountContext as BankIntegrationAccountContextGQL } from '@condo/domains/banking/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<BankIntegrationAccountContext, BankIntegrationAccountContextCreateInput, BankIntegrationAccountContextUpdateInput, QueryAllBankIntegrationAccountContextsArgs>(BankIntegrationAccountContextGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
