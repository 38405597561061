/**
 * Generated by `createschema banking.BankAccountReportTask 'account:Relationship:BankAccount:CASCADE; organization:Relationship:Organization:CASCADE; status:Select:processing,completed,cancelled,errored; progress:Integer; meta:Json;'`
 */

import {
    BankAccountReportTask,
    BankAccountReportTaskCreateInput,
    BankAccountReportTaskUpdateInput,
    QueryAllBankAccountReportTasksArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { BankAccountReportTask as BankAccountReportTaskGQL } from '@condo/domains/banking/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<BankAccountReportTask, BankAccountReportTaskCreateInput, BankAccountReportTaskUpdateInput, QueryAllBankAccountReportTasksArgs>(BankAccountReportTaskGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
