/**
 * Generated by `createschema banking.BankContractorAccount 'name:Text; organization:Relationship:Organization:CASCADE; costItem?:Relationship:BankCostItem:SET_NULL; tin:Text; country:Text; routingNumber:Text; number:Text; currencyCode:Text; importId?:Text; territoryCode?:Text; bankName?:Text; meta?:Json; tinMeta?:Json; routingNumberMeta?:Json'`
 */

import {
    BankContractorAccount,
    BankContractorAccountCreateInput,
    BankContractorAccountUpdateInput,
    QueryAllBankContractorAccountsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { BankContractorAccount as BankContractorAccountGQL } from '@condo/domains/banking/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
    useCount,
} = generateReactHooks<BankContractorAccount, BankContractorAccountCreateInput, BankContractorAccountUpdateInput, QueryAllBankContractorAccountsArgs>(BankContractorAccountGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
    useCount,
}
