/**
 * Generated by `createschema meter.MeterReadingFilterTemplate 'name:Text; employee:Relationship:OrganizationEmployee:CASCADE; filters:Json'`
 */

import {
    MeterReadingFilterTemplate,
    MeterReadingFilterTemplateCreateInput,
    MeterReadingFilterTemplateUpdateInput,
    QueryAllMeterReadingFilterTemplatesArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { MeterReadingFilterTemplate as MeterReadingFilterTemplateGQL } from '@condo/domains/meter/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<MeterReadingFilterTemplate, MeterReadingFilterTemplateCreateInput, MeterReadingFilterTemplateUpdateInput, QueryAllMeterReadingFilterTemplatesArgs>(MeterReadingFilterTemplateGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
