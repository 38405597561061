/**
 * Generated by `createschema miniapp.B2BAppAccessRightSet 'app:Relationship:B2BApp:CASCADE;'`
 */

import {
    B2BAppAccessRightSet,
    B2BAppAccessRightSetCreateInput,
    B2BAppAccessRightSetUpdateInput,
    QueryAllB2BAppAccessRightSetsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { B2BAppAccessRightSet as B2BAppAccessRightSetGQL } from '@condo/domains/miniapp/gql'


const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<B2BAppAccessRightSet, B2BAppAccessRightSetCreateInput, B2BAppAccessRightSetUpdateInput, QueryAllB2BAppAccessRightSetsArgs>(B2BAppAccessRightSetGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
