/**
 * Generated by `createschema contact.ContactExportTask 'status:Select:processing,completed,error; format:Select:excel;exportedRecordsCount:Integer; totalRecordsCount:Integer; file?:File; meta?:Json'`
 */

import {
    ContactExportTask,
    ContactExportTaskCreateInput,
    ContactExportTaskUpdateInput,
    QueryAllContactExportTasksArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { ContactExportTask as ContactExportTaskGQL } from '@condo/domains/contact/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<ContactExportTask, ContactExportTaskCreateInput, ContactExportTaskUpdateInput, QueryAllContactExportTasksArgs>(ContactExportTaskGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
