/**
 * Generated by `createschema billing.BillingReceiptFile 'file:File;context:Relationship:BillingIntegrationOrganizationContext:CASCADE;receipt:Relationship:BillingReceipt:CASCADE;controlSum:Text'`
 */

import {
    BillingReceiptFile,
    BillingReceiptFileCreateInput,
    BillingReceiptFileUpdateInput,
    QueryAllBillingReceiptFilesArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { BillingReceiptFile as BillingReceiptFileGQL } from '@condo/domains/billing/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<BillingReceiptFile, BillingReceiptFileCreateInput, BillingReceiptFileUpdateInput, QueryAllBillingReceiptFilesArgs>(BillingReceiptFileGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
