/**
 * Generated by `createschema notification.NotificationUserSetting 'user:Relationship:User:CASCADE; messageType:Text; messageTransport:Text; isEnabled:Checkbox'`
 */

import {
    NotificationUserSetting,
    NotificationUserSettingCreateInput,
    NotificationUserSettingUpdateInput,
    QueryAllNotificationUserSettingsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { NotificationUserSetting as NotificationUserSettingGQL } from '@condo/domains/notification/gql'

// TODO(codegen): write utils like convertToFormState and formValuesProcessor if needed, otherwise delete this TODO

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<NotificationUserSetting, NotificationUserSettingCreateInput, NotificationUserSettingUpdateInput, QueryAllNotificationUserSettingsArgs>(NotificationUserSettingGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
