/**
 * Generated by `createschema meter.MeterReadingExportTask 'status:Select:processing,completed,error; format:Select:excel; exportedRecordsCount:Integer; totalRecordsCount:Integer; file?:File; meta?:Json; where:Json; sortBy:Json; locale:Text; timeZone:Text; user:Relationship:User:CASCADE;'`
 */

import {
    MeterReadingExportTask,
    MeterReadingExportTaskCreateInput,
    MeterReadingExportTaskUpdateInput,
    QueryAllMeterReadingExportTasksArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { MeterReadingExportTask as MeterReadingExportTaskGQL } from '@condo/domains/meter/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<MeterReadingExportTask, MeterReadingExportTaskCreateInput, MeterReadingExportTaskUpdateInput, QueryAllMeterReadingExportTasksArgs>(MeterReadingExportTaskGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
