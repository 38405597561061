/**
 * Generated by `createschema miniapp.B2BAppPermission 'app:Relationship:B2BApp:PROTECT; key:Text'`
 */

import {
    B2BAppPermission,
    B2BAppPermissionCreateInput,
    B2BAppPermissionUpdateInput,
    QueryAllB2BAppPermissionsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { B2BAppPermission as B2BAppPermissionGQL } from '@condo/domains/miniapp/gql'


const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<B2BAppPermission, B2BAppPermissionCreateInput, B2BAppPermissionUpdateInput, QueryAllB2BAppPermissionsArgs>(B2BAppPermissionGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
