/**
 * Generated by `createschema news.OrganizationNewsItem 'organization:Relationship:Organization:CASCADE; title:Text; body:Text; type:Select:common,emergency'`
 * In most cases you should not change it by hands
 * Please, don't remove `AUTOGENERATE MARKER`s
 */

const { gql } = require('@apollo/client')

const { generateGqlQueries } = require('@open-condo/codegen/generate.gql')

const { ADDRESS_META_SUBFIELDS_TABLE_LIST } = require('@condo/domains/property/schema/fields/AddressMetaField')

const COMMON_FIELDS = 'id dv sender { dv fingerprint } v deletedAt newId createdBy { id name } updatedBy { id name } createdAt updatedAt'

const NEWS_ITEM_FIELDS = `{ organization { id } number title body type validBefore sendAt sentAt isPublished publishedAt ${COMMON_FIELDS} compactScopes { count firstOnes { id unitType unitName property { address addressMeta { ${ADDRESS_META_SUBFIELDS_TABLE_LIST} } } } } }`
const NewsItem = generateGqlQueries('NewsItem', NEWS_ITEM_FIELDS)

const NEWS_ITEM_SCOPE_FIELDS = `{ type newsItem { id organization { id } number isPublished } property { id address addressMeta { ${ADDRESS_META_SUBFIELDS_TABLE_LIST} } } unitType unitName ${COMMON_FIELDS} }`
const NewsItemScope = generateGqlQueries('NewsItemScope', NEWS_ITEM_SCOPE_FIELDS)

const NEWS_ITEM_TEMPLATE_FIELDS = `{ organization { id } name category title body type ${COMMON_FIELDS} }`
const NewsItemTemplate = generateGqlQueries('NewsItemTemplate', NEWS_ITEM_TEMPLATE_FIELDS)

const NEWS_ITEM_USER_READ_FIELDS = `{ newsItem { id } user { id } ${COMMON_FIELDS} }`
const NewsItemUserRead = generateGqlQueries('NewsItemUserRead', NEWS_ITEM_USER_READ_FIELDS)

const GET_NEWS_ITEMS_RECIPIENTS_COUNTERS_MUTATION = gql`
    query getNewsItemsRecipientsCounters ($data: GetNewsItemsRecipientsCountersInput!) {
        result: getNewsItemsRecipientsCounters(data: $data) { propertiesCount, unitsCount, receiversCount }
    }
`

const RECIPIENTS_EXPORT_TASK_FIELDS = `{ user { id locale } organization { id } scopes status file { id originalFilename publicUrl mimetype } ${COMMON_FIELDS} }`
const NewsItemRecipientsExportTask = generateGqlQueries('NewsItemRecipientsExportTask', RECIPIENTS_EXPORT_TASK_FIELDS)

const NEWS_ITEM_SHARING_FIELDS = `{ b2bAppContext { id app { newsSharingConfig { name getRecipientsCountersUrl } } } newsItem { id } sharingParams status statusMessage lastPostRequest ${COMMON_FIELDS} createdAt }`
const NewsItemSharing = generateGqlQueries('NewsItemSharing', NEWS_ITEM_SHARING_FIELDS)

const GET_NEWS_SHARING_RECIPIENTS_MUTATION = gql`
    query getNewsSharingRecipients ($data: GetNewsSharingRecipientsInput!) {
        result: getNewsSharingRecipients(data: $data) { id, name, receiversCount }
    }
`

const GET_NEWS_SHARING_RECIPIENTS_COUNTERS_QUERY = gql`
    query getNewsSharingRecipientsCounters ($data: GetNewsSharingRecipientsCountersInput!) {
        result: getNewsSharingRecipientsCounters(data: $data) { receiversCount }
    }
`

/* AUTOGENERATE MARKER <CONST> */

module.exports = {
    NewsItem,
    NewsItemScope,
    NewsItemTemplate,
    NewsItemUserRead,
    GET_NEWS_ITEMS_RECIPIENTS_COUNTERS_MUTATION,
    NewsItemRecipientsExportTask,
    NewsItemSharing,
    GET_NEWS_SHARING_RECIPIENTS_MUTATION,
    NEWS_ITEM_SCOPE_FIELDS,
    GET_NEWS_SHARING_RECIPIENTS_COUNTERS_QUERY,
    /* AUTOGENERATE MARKER <EXPORTS> */
}
