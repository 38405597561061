const TOUR_CATEGORY = 'TOUR'
const DASHBOARD_CATEGORY = 'DASHBOARD'
const COMMUNICATION_CATEGORY = 'COMMUNICATION'
const PROPERTIES_CATEGORY = 'PROPERTIES'
const RESIDENTS_CATEGORY = 'RESIDENTS'
const EMPLOYEES_CATEGORY = 'EMPLOYEES'
const MARKET_CATEGORY = 'MARKET'
const BILLING_CATEGORY = 'BILLING'
const METERS_CATEGORY = 'METERS'
const MINIAPPS_CATEGORY = 'MINIAPPS'
const SETTINGS_CATEGORY = 'SETTINGS'

const ALL_MENU_CATEGORIES = [
    TOUR_CATEGORY,
    DASHBOARD_CATEGORY,
    COMMUNICATION_CATEGORY,
    PROPERTIES_CATEGORY,
    RESIDENTS_CATEGORY,
    EMPLOYEES_CATEGORY,
    MARKET_CATEGORY,
    BILLING_CATEGORY,
    METERS_CATEGORY,
    MINIAPPS_CATEGORY,
    SETTINGS_CATEGORY,
]

const DEFAULT_MENU_CATEGORY = MINIAPPS_CATEGORY

module.exports = {
    TOUR_CATEGORY,
    DASHBOARD_CATEGORY,
    COMMUNICATION_CATEGORY,
    PROPERTIES_CATEGORY,
    RESIDENTS_CATEGORY,
    EMPLOYEES_CATEGORY,
    MARKET_CATEGORY,
    BILLING_CATEGORY,
    METERS_CATEGORY,
    MINIAPPS_CATEGORY,
    SETTINGS_CATEGORY,
    ALL_MENU_CATEGORIES,
    DEFAULT_MENU_CATEGORY,
}