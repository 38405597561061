/**
 * This file is autogenerated by `createschema meter.MeterResource 'name:Text;'`
 * In most cases you should not change it by hands. And please don't remove `AUTOGENERATE MARKER`s
 */

import { BulbIcon } from '@condo/domains/common/components/icons/BulbIcon'
import { FireIcon } from '@condo/domains/common/components/icons/FireIcon'
import { RadiatorIcon } from '@condo/domains/common/components/icons/RadiatorIcon'
import { SnowflakeIcon } from '@condo/domains/common/components/icons/SnowflakeIcon'
import { StoveIcon } from '@condo/domains/common/components/icons/StoveIcon'
import {
    COLD_WATER_METER_RESOURCE_ID, ELECTRICITY_METER_RESOURCE_ID,
    GAS_SUPPLY_METER_RESOURCE_ID, HEAT_SUPPLY_METER_RESOURCE_ID,
    HOT_WATER_METER_RESOURCE_ID,
} from '@condo/domains/meter/constants/constants'

export * as MeterResource from './MeterResource'
export * as MeterReadingSource from './MeterReadingSource'
export * as MeterReading from './MeterReading'
export * as MeterReadingForOrganization from './MeterReadingForOrganization'
export * as Meter from './Meter'
export * as MeterForOrganization from './MeterForOrganization'
export * as MeterReadingFilterTemplate from './MeterReadingFilterTemplate'
export * as PropertyMeter from './PropertyMeter'
export * as PropertyMeterReading from './PropertyMeterReading'
export * as MeterReportingPeriod from './MeterReportingPeriod'
export * as MeterResourceOwner from './MeterResourceOwner'
export * as MeterReadingsImportTask from './MeterReadingsImportTask'
export * as MeterReadingExportTask from './MeterReadingExportTask'
/* AUTOGENERATE MARKER <IMPORT-EXPORT> */


export const resourceIds = [
    COLD_WATER_METER_RESOURCE_ID,
    HOT_WATER_METER_RESOURCE_ID,
    GAS_SUPPLY_METER_RESOURCE_ID,
    ELECTRICITY_METER_RESOURCE_ID,
    HEAT_SUPPLY_METER_RESOURCE_ID,
]

export const resourceIdIconMap = {
    [COLD_WATER_METER_RESOURCE_ID]: SnowflakeIcon,
    [HOT_WATER_METER_RESOURCE_ID]: FireIcon,
    [GAS_SUPPLY_METER_RESOURCE_ID]: StoveIcon,
    [ELECTRICITY_METER_RESOURCE_ID]: BulbIcon,
    [HEAT_SUPPLY_METER_RESOURCE_ID]: RadiatorIcon,
}

export const resourceIdToCreateMeterTitleIdMap = {
    [COLD_WATER_METER_RESOURCE_ID]: 'pages.condo.meter.AddColdWaterMeterModalTitle',
    [HOT_WATER_METER_RESOURCE_ID]: 'pages.condo.meter.AddHotWaterMeterModalTitle',
    [GAS_SUPPLY_METER_RESOURCE_ID]: 'pages.condo.meter.AddGasSupplyMeterModalTitle',
    [ELECTRICITY_METER_RESOURCE_ID]: 'pages.condo.meter.AddElectricityMeterModalTitle',
    [HEAT_SUPPLY_METER_RESOURCE_ID]: 'pages.condo.meter.AddHeatSupplyMeterModalTitle',
}

export type MeterPageTypes = 'meter-reading' | 'meter' | 'meter-reporting-period' | 'property-meter' | 'property-meter-reading'
type MeterPageTypesSwitch = {
    meterReading: MeterPageTypes
    meter: MeterPageTypes
    reportingPeriod: MeterPageTypes
    propertyMeter: MeterPageTypes
    propertyMeterReading: MeterPageTypes
}
export const METER_TAB_TYPES: MeterPageTypesSwitch = {
    meterReading: 'meter-reading',
    meter: 'meter',
    reportingPeriod: 'meter-reporting-period',
    propertyMeter: 'property-meter',
    propertyMeterReading: 'property-meter-reading',
}

export type MeterTypes = 'unit' | 'property'
type MeterTypeSwitch = {
    unit: MeterTypes
    property: MeterTypes
}
export const METER_TYPES: MeterTypeSwitch = {
    unit: 'unit',
    property: 'property',
}