/**
 * Generated by `createschema notification.MessageBatch 'messageType:Text; title:Text; message:Text; deepLink?:Text; targets:Json; status:Select:created,processing,failed,done; processingMeta?:Json;'`
 */

import {
    MessageBatch,
    MessageBatchCreateInput,
    MessageBatchUpdateInput,
    QueryAllMessageBatchesArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { MessageBatch as MessageBatchGQL } from '@condo/domains/notification/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<MessageBatch, MessageBatchCreateInput, MessageBatchUpdateInput, QueryAllMessageBatchesArgs>(MessageBatchGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
