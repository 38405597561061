/**
 * Generated by `createschema banking.BankIntegration 'name:Text'`
 */

import {
    BankIntegration,
    BankIntegrationCreateInput,
    BankIntegrationUpdateInput,
    QueryAllBankIntegrationsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { BankIntegration as BankIntegrationGQL } from '@condo/domains/banking/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<BankIntegration, BankIntegrationCreateInput, BankIntegrationUpdateInput, QueryAllBankIntegrationsArgs>(BankIntegrationGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
