/**
 * Generated by `createschema miniapp.MessageAppBlackList 'app?:Relationship:B2CApp:CASCADE; description:Text'`
 */

import {
    MessageAppBlackList,
    MessageAppBlackListCreateInput,
    MessageAppBlackListUpdateInput,
    QueryAllMessageAppBlackListsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { MessageAppBlackList as MessageAppBlackListGQL } from '@condo/domains/miniapp/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<MessageAppBlackList, MessageAppBlackListCreateInput, MessageAppBlackListUpdateInput, QueryAllMessageAppBlackListsArgs>(MessageAppBlackListGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
