/**
 * Generated by `createschema onboarding.OnBoarding 'completed:Checkbox; stepsTransitions:Json;'`
 * In most cases you should not change it by hands
 * Please, don't remove `AUTOGENERATE MARKER`s
 */

const { gql } = require('graphql-tag')

const { generateGqlQueries } = require('@open-condo/codegen/generate.gql')


const COMMON_FIELDS = 'id dv sender { dv fingerprint } v deletedAt newId createdBy { id name } updatedBy { id name } createdAt updatedAt'

const TOUR_STEP_FIELDS = `{ organization { id } type status order ${COMMON_FIELDS} }`
const TourStep = generateGqlQueries('TourStep', TOUR_STEP_FIELDS)

const SYNC_TOUR_STEPS_MUTATION = gql`
    mutation syncTourSteps ($data: SyncTourStepsInput!) {
        result: syncTourSteps(data: $data) { ok }
    }
`

const USER_HELP_REQUEST_FIELDS = `{ type organization { id } phone meta isReadyToSend ${COMMON_FIELDS} }`
const UserHelpRequest = generateGqlQueries('UserHelpRequest', USER_HELP_REQUEST_FIELDS)

const USER_HELP_REQUEST_FILE_FIELDS = `{ userHelpRequest { id } file { id publicUrl } ${COMMON_FIELDS} }`
const UserHelpRequestFile = generateGqlQueries('UserHelpRequestFile', USER_HELP_REQUEST_FILE_FIELDS)

/* AUTOGENERATE MARKER <CONST> */

module.exports = {
    TourStep,
    SYNC_TOUR_STEPS_MUTATION,
    UserHelpRequest,
    UserHelpRequestFile,
/* AUTOGENERATE MARKER <EXPORTS> */
}
