/**
 * Generated by `createschema miniapp.B2BAppContext 'integration:Relationship:B2BApp:PROTECT; organization:Relationship:Organization:PROTECT; settings:Json; state:Json;'`
 */

import {
    B2BAppContext,
    B2BAppContextCreateInput,
    B2BAppContextUpdateInput,
    QueryAllB2BAppContextsArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { B2BAppContext as B2BAppContextGQL } from '@condo/domains/miniapp/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<B2BAppContext, B2BAppContextCreateInput, B2BAppContextUpdateInput, QueryAllB2BAppContextsArgs>(B2BAppContextGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
