/**
 * Generated by `createschema meter.MeterReadingSource 'type:Select:call,mobile_app,billing; name:Text;'`
 */

import {
    MeterReadingSource,
    MeterReadingSourceCreateInput,
    MeterReadingSourceUpdateInput,
    QueryAllMeterReadingSourcesArgs,
} from '@app/condo/schema'

import { generateReactHooks } from '@open-condo/codegen/generate.hooks'

import { MeterReadingSource as MeterReadingSourceGQL } from '@condo/domains/meter/gql'

const {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
} = generateReactHooks<MeterReadingSource, MeterReadingSourceCreateInput, MeterReadingSourceUpdateInput, QueryAllMeterReadingSourcesArgs>(MeterReadingSourceGQL)

export {
    useObject,
    useObjects,
    useCreate,
    useUpdate,
    useSoftDelete,
}
